
import { Component, Vue, Watch } from 'vue-property-decorator';

import { UserModule } from '@/store/modules';
import { IAuthUser, IUserUpdateFields } from '@/types/user.types';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { ICauseAndCategorySelectOptions, ICauseCategorySettings, ICauseSettings } from '@/types/cause.types';
import { AdminService, UserService } from '@/services';

@Component
export default class ChangeCause extends Vue {
  activeTenant = tenants[ActiveTenant];
  causeTitle = this.activeTenant.causeSetting.title;
  causeDescription = this.activeTenant.causeSetting.description;
  newCauseId = ''; // dropdown cause ID selected
  newCategoryId = ''; // dropdown category ID selected
  causeSelected = this.user?.cause; // Cause supported Obeject
  causesCategories: ICauseCategorySettings[] = [];
  causes: ICauseSettings[] = [];
  categoryOptions: ICauseAndCategorySelectOptions[] | null = null;
  causeOptions: ICauseAndCategorySelectOptions[] | null = null;

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  get currentCause(): string {
    return this.user?.cause?.name as string;
  }

  async getCausesCategories(): Promise<ICauseAndCategorySelectOptions[]> {
    try {
      this.causesCategories = await AdminService.fetchCauseCategories();
      this.categoryOptions = this.causesCategories.map((category) => ({
        label: category.name,
        value: category.id
      }));
      return this.categoryOptions;
    } catch (e: any) {
      this.$notifyError('Something went wrong');
      return [];
    }
  }

  async getCausesByCategory(categoryId?: string): Promise<void> {
    try {
      this.causes = await AdminService.fetchCauses(categoryId);
      this.causeOptions = this.causes.map((cause) => ({
        label: cause.name,
        value: cause.id
      }));
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  async getCauseById(causeId: string): Promise<void> {
    try {
      const response = await AdminService.fetchCauses(undefined, causeId);
      this.causeSelected = response[0];
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  async updateCause(): Promise<void> {
    const payload = { causeId: this.newCauseId };
    try {
      await UserService.updateProfile(payload as IUserUpdateFields);
      this.$notifySuccess('Your profile was saved successfully');
      let user = await UserService.fetchUser();
      UserModule.setUser(user);
    } catch (e) {
      this.$notifyError("Your profile hasn't been saved. Please try again");
    }
  }

  async created(): Promise<void> {
    await this.getCausesCategories();
    await this.getCausesByCategory();
    this.newCauseId = this.causeSelected?.id as string;
    this.newCategoryId = this.causeSelected?.categoryId as string;
  }

  @Watch('newCategoryId')
  async onCategoryChange(newCategoryId: string): Promise<void> {
    await this.getCausesByCategory(newCategoryId);
  }

  @Watch('newCauseId')
  async onCauseChange(newCauseId: string): Promise<void> {
    await this.getCauseById(newCauseId);
  }
}
